
// 导入moment.ja
import moment from 'moment'


export function getName(val, list) {
    let str = "";
    list.map((item => {
        if (item.value == val) str = item.label
    }))
    if (!str) str = '-'
    return str
}

export function getIdName(val, list) {
    let str = "";
    list.map((item => {
        if (item.id == val) str = item.name
    }))
    if (!str) str = '-'
    return str
}

export function getchildName(val, list) {
    let str = "";
    // if (!list) return
    list.map((item => {
        if (item.id == val) str = item.name;
        item.children.map(index => {
            if (index.id == val) str = index.name;
            index.children.map(i => {
                if (i.id == val) str = i.name;
            })
        })
    }))
    if (!str) str = '-'
    return str
}

export function getChinese(item) {
    // const pattern = /[\u4e00-\u9fa5]+/g; // 匹配中文字符的正则表达式
    if (item.summary) return item.summary
    if (!item.content) return ""
    let result = extractTextFromHTML(item.content);
    // const result = item.content.match(pattern); // 使用 match 方法匹配中文字符，并使用 join 方法将数组转换为字符串
    if (!result) return ""
    if (item.contentType == 3) {
        result = ""
    }
    return result
}

function extractTextFromHTML(htmlString) {
    let tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    return tempElement.innerText || tempElement.textContent;
}

export function getDay(val) {
    if (!val) return "-"
    let day = moment(val).date()
    let time = day < 10 ? ('0' + day) : day;
    return time
}

export function getMomth(val) {
    if (!val) return "-"
    let momthT = moment(val).month();
    let time = moment(val).year() + "-" + ((momthT + 1) < 10 ? ('0' + (momthT + 1)) : (momthT + 1));
    return time
}

export function getPYear(val) {
    if (!val) return "-";
    val = val.replace(/-/g, '/')
    let day = moment(val).year();
    return day
}

export function getPMomth(val) {
    if (!val) return "-";
    val = val.replace(/-/g, '/')
    let momthT = moment(val).month() + 1;
    let day = moment(val).date();
    let time = (momthT < 10 ? ('0' + momthT) : momthT) + "/" + (day < 10 ? ('0' + day) : day);
    return time
}

// 图片地址过滤
export function getImgurl(val) {
    if (!val) {
        return require("@/assets/imgs/placeholder.jpg")
    } else {
        return val
    }
}