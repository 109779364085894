import request from '@/api/axios'

//  栏目分页
export const columnPage = (data) => {
  return request({
    url: '/column/page',
    method: 'get',
    params: data,
  })
}

//  获取文章列表
export const contentpage = (data) => {
  return request({
    url: '/content/page',
    method: 'get',
    params: data,
  })
}

//  主题列表
export const themeList = (data) => {
  return request({
    url: '/theme/list',
    method: 'get',
    params: data,
  })
}

// 获取文章详情
export const detail = (data) => {
  return request({
    url: '/home/detail',
    method: 'get',
    params: data,
  })
}

// 获取热门推荐
export const hotPage = (data) => {
  return request({
    url: '/content/hot/page',
    method: 'get',
    params: data,
  })
}

// 浏览量
export const visit = () => {
  return request({
    url: '/home/visit',
    method: 'get',
  })
}

// 点星星
export const homeStar = (data) => {
  return request({
    url: '/home/star',
    method: 'get',
    params: data,
  })
}

// 点赞和转发 type:1 点赞。type:2转发
export const homeLike = (data) => {
  return request({
    url: '/home/like',
    method: 'get',
    params: data,
  })
}
// 校长邮箱提交
export const sendMail = (data) => {
  return request({
    url: '/content/sendMail',
    method: 'post',
    data: data,
  })
}
