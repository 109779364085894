<template>
  <div class="nullData">
    <img src="@/assets/imgs/null.png" alt="" />

    <p>暂无内容，去其他地方看看吧～</p>
  </div>
</template>

<script>
export default {
  name: "nullData",
  data() {
    return {};
  },
  components: {},
  created() {},
  methods: {},
};
</script>
<style scoped lang='scss'>
.nullData {
  text-align: center;
  padding-top: vw(60);
  p {
    color: #a5a1a1;
    font-size: vw(16);
    margin-top: 18px;
  }
}
</style>